import React from 'react'
import styled from 'styled-components'

import Nav from '../components/nav'
import Footer from '../components/footer'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const AboutUs = () => {
  useHotjarScript()
  return (
    <>
      <Nav theme="light" />
      <Container></Container>
      <Footer />
    </>
  )
}

const Container = styled.main`
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 700px;
  max-width: 92.5vw;
  margin: auto;
`

export default AboutUs
